var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page_body"},[_c('ext-bridge',{on:{"hintChanged":_vm.hintChanged}}),_c('custom-breadcrumb',{staticClass:"breadcrumbs",attrs:{"navigations":_vm.navs}}),_c('div',{class:['table-container']},[_c('div',{staticClass:"table-container-top"},[_c('div',{staticClass:"product-map-block"},[_c('p',{staticClass:"product-map-txt"},[_vm._v("Map Product")]),_c('p',{staticClass:"product-map-desc"},[_vm._v(" Enter Myntra Pppmp SKU to map and Enable to make product live on marketplace ")]),_c('div',{staticClass:"image-name-block"},[_c('div',[(_vm.productData.images && _vm.productData.images.length)?_c('img',{attrs:{"src":_vm.productData.images && _vm.productData.images.length
                  ? _vm.productData.images[0].replace('original', '75x0')
                  : '../assets/admin/svgs/no_image.svg',"alt":"Image"}}):_c('inline-svg',{staticClass:"prod-image",attrs:{"src":'no_image'}})],1),_c('div',{staticClass:"product-brand-name-block"},[_c('p',{staticClass:"product-name-block"},[_vm._v(_vm._s(_vm.productData.name))]),_c('p',{staticClass:"brand-name-block"},[_vm._v(_vm._s(_vm.productData.brand.name))]),_c('div',{staticClass:"mapped-active-sku-block"},[_c('div',{staticClass:"sku-count-block"},[_vm._v(" Mapped SKUs : "+_vm._s(_vm.calculateSkuCount(_vm.productData, "mapped"))+"/"+_vm._s(_vm.productData.articles.length)+" ")]),_c('div',{staticClass:"sku-count-block active-count-block"},[_vm._v(" Active SKUs : "+_vm._s(_vm.calculateSkuCount(_vm.productData, "active"))+"/"+_vm._s(_vm.productData.articles.length)+" ")])])])])]),_c('div',{class:['pad-left-top', 'cancel-btn']},[_c('nitrozen-button',{directives:[{name:"strokeBtn",rawName:"v-strokeBtn"}],attrs:{"theme":"secondary"},on:{"click":_vm.cancelProductMapping}},[_vm._v("Cancel ")])],1),_c('div',{class:[
          'pad-left-top',
          'save-btn',
        ],attrs:{"id":"save"}},[_c('nitrozen-button',{directives:[{name:"flatBtn",rawName:"v-flatBtn"}],attrs:{"theme":"secondary","disabled":_vm.disableSaveButton},on:{"click":_vm.saveSkuMapping}},[_vm._v(" Save ")])],1)]),(!_vm.isEmpty(_vm.productData))?[_c('div',{staticClass:"headers"},_vm._l((_vm.headers),function(header,index){return _c('div',{key:index,class:[
            'header',
            'regular-xsp',
            'cl-Mako',
            {
              'sync-col-width':
                header.title === 'Status' ||
                header.title === 'Product Details',
            },
          ]},[_vm._v(" "+_vm._s(header.title)+" "),(header.tooltip)?_c('nitrozen-tooltip',{staticClass:"header-tooltip",attrs:{"position":"top"}},[_vm._v(" "+_vm._s(header.tooltip)+" ")]):_vm._e()],1)}),0),_c('div',{staticClass:"row-container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"sku-map-block"},_vm._l((_vm.productData.articles),function(sku,skuIndex){return _c('div',{key:skuIndex,class:['sku-row']},_vm._l((_vm.headers),function(header,index){return _c('div',{key:index,class:[
                  'row-value',
                  'cl-Mako',
                  {
                    'sync-col-width':
                      header.title === 'Status' || header.title === 'Details',
                  },
                  {
                    'location-code': header.title === 'Location Code',
                  },
                  {
                    'input-mkp-id':
                      sku.error != '' &&
                      header.key == 'marketplace_identifiers',
                  },
                  {
                    'hint-element':
                      _vm.isHintActive &&
                      _vm.currentHintStage == 0 &&
                      skuIndex == 0 &&
                      (header.key == 'marketplace_identifiers' ||
                        header.key == 'active'),
                  },
                  {
                    'row-value-flex': _vm.mappingsErrors.length || _vm.isHintActive,
                  },
                ]},[(_vm.isHintActive)?_c('nitrozen-hint',{attrs:{"steps":_vm.hintSteps,"hintType":"prod_hint_status"},on:{"hintInactive":_vm.setHintInactive}}):_vm._e(),(header.type === 'text')?[_c('div',{staticClass:"text-overflow regular-xs",attrs:{"id":header.key}},[_vm._v(" "+_vm._s(sku[header.key] || "-")+" ")])]:_vm._e(),(header.type === 'badge')?[_c('div',[_c('nitrozen-badge',{attrs:{"state":sku[header.key] ? 'success' : 'error'}},[_vm._v(_vm._s(sku[header.key] ? "ACTIVE" : "INACTIVE")+" ")])],1)]:_vm._e(),(header.type === 'input')?[_c('div',{class:[
                      'handle',
                    ],attrs:{"id":header.key}},[(header.key == 'marketplace_identifiers')?_c('nitrozen-input',{class:['regular-xs'],attrs:{"type":header.keyType || 'text'},model:{value:(sku[header.key].sku),callback:function ($$v) {_vm.$set(sku[header.key], "sku", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"sku[header.key].sku"}}):_vm._e()],1),_c('div',{staticClass:"sku-error"},[(
                        header.key == 'marketplace_identifiers' && sku.error
                      )?_c('nitrozen-error',[_vm._v(_vm._s(sku.error)+" ")]):_vm._e()],1)]:_vm._e(),(header.type === 'switch')?[_c('div',{attrs:{"id":header.key}},[_c('nitrozen-toggle',{model:{value:(sku[header.key]),callback:function ($$v) {_vm.$set(sku, header.key, $$v)},expression:"sku[header.key]"}})],1)]:_vm._e()],2)}),0)}),0)])])]:_vm._e()],2),(_vm.inProgress)?_c('loader',{staticClass:"loading"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }