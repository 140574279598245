<template>
  <div class="page_body">
    <ext-bridge v-on:hintChanged="hintChanged"></ext-bridge>
    <custom-breadcrumb
      :navigations="navs"
      class="breadcrumbs"
    ></custom-breadcrumb>
    <div :class="['table-container']">
      <div class="table-container-top">
        <div class="product-map-block">
          <p class="product-map-txt">Map Product</p>
          <p class="product-map-desc">
            Enter Myntra Pppmp SKU to map and Enable to make product live on
            marketplace
          </p>
          <div class="image-name-block">
            <div>
              <img
                v-if="productData.images && productData.images.length"
                :src="
                  productData.images && productData.images.length
                    ? productData.images[0].replace('original', '75x0')
                    : '../assets/admin/svgs/no_image.svg'
                "
                alt="Image"
              />
              <inline-svg
                v-else
                :src="'no_image'"
                class="prod-image"
              ></inline-svg>
            </div>
            <div class="product-brand-name-block">
              <p class="product-name-block">{{ productData.name }}</p>
              <p class="brand-name-block">{{ productData.brand.name }}</p>
              <div class="mapped-active-sku-block">
                <div class="sku-count-block">
                  Mapped SKUs :
                  {{ calculateSkuCount(productData, "mapped") }}/{{
                    productData.articles.length
                  }}
                </div>
                <div class="sku-count-block active-count-block">
                  Active SKUs :
                  {{ calculateSkuCount(productData, "active") }}/{{
                    productData.articles.length
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="['pad-left-top', 'cancel-btn']">
          <nitrozen-button
            theme="secondary"
            v-strokeBtn
            @click="cancelProductMapping"
            >Cancel
          </nitrozen-button>
        </div>
        <div
          :class="[
            'pad-left-top',
            'save-btn',
          ]"
          id="save"
        >
          <nitrozen-button 
              theme="secondary" 
              v-flatBtn 
              @click="saveSkuMapping"  
              :disabled=disableSaveButton
          >
            Save
          </nitrozen-button>
        </div>
      </div>
      <template v-if="!isEmpty(productData)">
        <div class="headers">
          <div
            v-for="(header, index) in headers"
            :key="index"
            :class="[
              'header',
              'regular-xsp',
              'cl-Mako',
              {
                'sync-col-width':
                  header.title === 'Status' ||
                  header.title === 'Product Details',
              },
            ]"
          >
            {{ header.title }}
            <nitrozen-tooltip
              class="header-tooltip"
              position="top"
              v-if="header.tooltip"
            >
              {{ header.tooltip }}
            </nitrozen-tooltip>
          </div>
        </div>
        <div class="row-container">
          <div class="row">
            <div class="sku-map-block">
              <div
                v-for="(sku, skuIndex) in productData.articles"
                :key="skuIndex"
                :class="['sku-row']"
              >
                <div
                  v-for="(header, index) in headers"
                  :key="index"
                  :class="[
                    'row-value',
                    'cl-Mako',
                    {
                      'sync-col-width':
                        header.title === 'Status' || header.title === 'Details',
                    },
                    {
                      'location-code': header.title === 'Location Code',
                    },
                    {
                      'input-mkp-id':
                        sku.error != '' &&
                        header.key == 'marketplace_identifiers',
                    },
                    {
                      'hint-element':
                        isHintActive &&
                        currentHintStage == 0 &&
                        skuIndex == 0 &&
                        (header.key == 'marketplace_identifiers' ||
                          header.key == 'active'),
                    },
                    {
                      'row-value-flex': mappingsErrors.length || isHintActive,
                    },
                  ]"
                >
                <nitrozen-hint :steps="hintSteps" v-if="isHintActive" @hintInactive="setHintInactive" hintType="prod_hint_status"></nitrozen-hint>
                  <template v-if="header.type === 'text'">
                    <div class="text-overflow regular-xs" :id="header.key">
                      {{ sku[header.key] || "-" }}
                    </div>
                    <!-- To get key from object type field -->
                  </template>
                  <template v-if="header.type === 'badge'">
                    <div>
                      <nitrozen-badge
                        :state="sku[header.key] ? 'success' : 'error'"
                        >{{ sku[header.key] ? "ACTIVE" : "INACTIVE" }}
                      </nitrozen-badge>
                    </div>
                  </template>
                  <template v-if="header.type === 'input'">
                    <!-- For nested keys -->
                    <div
                      :class="[
                        'handle',
                      ]"
                      :id ="header.key"
                    >
                      <nitrozen-input
                        :class="['regular-xs']"
                        v-if="header.key == 'marketplace_identifiers'"
                        v-model.trim="sku[header.key].sku"
                        :type="header.keyType || 'text'"
                      ></nitrozen-input>
                    </div>
                    <div class="sku-error">
                      <nitrozen-error
                        v-if="
                          header.key == 'marketplace_identifiers' && sku.error
                        "
                        >{{ sku.error }}
                      </nitrozen-error>
                    </div>
                  </template>
                  <template v-if="header.type === 'switch'">
                    <div
                      :id="header.key"
                    >
                      <nitrozen-toggle v-model="sku[header.key]">
                      </nitrozen-toggle>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <loader class="loading" v-if="inProgress"></loader>
  </div>
</template>

<script>
import {
  NitrozenButton,
  flatBtn,
  strokeBtn,
  NitrozenToggleBtn,
  NitrozenTooltip,
  NitrozenInput,
  NitrozenError,
  NitrozenBadge,
  NitrozenHint,
} from "@gofynd/nitrozen-vue";

import AdminMarketplacesService from "@/services/main.service";
import InlineSvg from "@/components/common/inline-svg";
import breadCrumb from "@/components/common/breadcrumbs.vue";
import loader from "@/components/common/adm-loader";
import extBridge from "./ext-bridge.vue";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

// This are common header fields and also all marhave different marketplaceId fields
// all extra fields are mentioned in EXTRA_HEADERS_FIELDS map
const HEADERS = [
  {
    title: "Seller Identifier",
    type: "text",
    key: "seller_identifier",
  },
  {
    title: "Size",
    key: "size",
    type: "text",
  },
  {
    title: "Myntra Ppmp SKU",
    key: "marketplace_identifiers",
    type: "input",
  },
];
const EXTRA_HEADERS_FIELDS = {
  extraFields: [
    {
      title: "Myntra Ppmp SKU",
      key: "marketplace_identifiers",
      type: "input",
    },
    {
      title: "Status",
      type: "switch",
      key: "active",
      // tooltip:
      //   "Selling location Inventory sync can be enabled only if GST is verified",
    },
  ],
  schema: {
    "Myntra Ppmp SKU": { type: "string" },
    Status: { type: "boolean" },
  },
};
const PAGINATION = {
  limit: 10,
  current: 1,
  total: 0,
};

const NAVS = [
  {
    title: "Home",
    link: "/",
  },
  { title: "Product", link: "/products" },
  { title: "Edit" },
];

export default {
  name: "edit-product",
  components: {
    "nitrozen-button": NitrozenButton,
    "nitrozen-toggle": NitrozenToggleBtn,
    "nitrozen-hint": NitrozenHint,
    "nitrozen-input": NitrozenInput,
    "nitrozen-tooltip": NitrozenTooltip,
    "nitrozen-badge": NitrozenBadge,
    "inline-svg": InlineSvg,
    loader,
    "ext-bridge": extBridge,
    "nitrozen-error": NitrozenError,
    "custom-breadcrumb": breadCrumb,
  },
  directives: {
    flatBtn,
    strokeBtn,
  },
  props: {
    productData: {},
  },
  data() {
    return {
      hints: [],
      navs: NAVS,
      headers: [],
      funnelCards: [],
      disableSaveButton: true,
      marketplace: "myntra_ppmp",
      companyId: "",
      searchText: "",
      searchPlaceholderText: "",
      statusList: [
        {
          text: "Active",
          value: "true",
        },
        {
          text: "Inactive",
          value: "false",
        },
      ],
      mappingsErrors: [],
      hintSteps: [
        {
          title: "2. Enter the Myntra Ppmp SKU in the input field",
          element: "#marketplace_identifiers",
          intro: `This SKU you can get from your Myntra PPMP marketplace for a particular product`,
          skipLabel: "X",
          hidePrev: true,
          position: "right",
        },
        {
          title: "3. Publish Product",
          element: "#active",
          intro:
            "This will make your product go LIVE on marketplace",
          position: "left",
        },
        {
          title: "4. One last step!",
          element: "#save",
          intro:
            "Click this “Save” button to map your first product.",
          position: "left",
        },
      ],
      inProgress: false,
      showDialog: false,
      csvPreviewTitle: "",
      pagination: { ...PAGINATION },
      cleanState: {},
      currentHintStage: 0,
      isHintActive: false,
    };
  },
  watch: {
    productData: {
      handler: function (newVal) {
        for (let i = 0; i < newVal.articles.length; i++) {
          if (newVal.articles[i].marketplace_identifiers.sku == undefined || newVal.articles[i].marketplace_identifiers.sku == "") {
            this.disableSaveButton = true;
          }
          else {
            this.disableSaveButton = false;
            break;
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    console.log("product is", this.productData);
    let hintStatus= localStorage.getItem("prod_hint_status");
    if(!hintStatus){
      this.isHintActive=true;
    }
    for (let i = 0; i < this.productData.articles.length; i++) {
      if (!isEmpty(this.productData.articles[i].marketplace_identifiers.sku)) {
        this.disableSaveButton = false;
        break;
      }
    }
    this.pagination.current = 1;
    this.pagination.limit = 10;
    this.searchPlaceholderText = "Search by location name";
    this.headers = this.modifyHeaders(cloneDeep(HEADERS));
  },
  methods: {
    handleHintMkpProdId() {
      this.currentHintStage = this.currentHintStage + 1;
    },
    handleHintOnSave() {
      this.isHintActive = false;
    },
    setHintInactive(){
      document.cookie = "introjs-dontShowAgain" + '=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';  
      this.isHintActive=false;
    },
    handlePrimaryEvt() {
      console.log("clicked ok");
    },
    modifyHeaders(headers) {
      let mkpStoreIdIndex = headers.findIndex(
        (header) => header.key === "marketplace_identifier"
      );
      // let qtyBufferIndex = headers.findIndex(
      //     header => header.key === 'quantity_buffer'
      // );
      // remove placeholder header marketplace_store_id
      headers.splice(mkpStoreIdIndex, 1);
      // put actual marketplace headers from extra headers map
      headers.splice(mkpStoreIdIndex, 0, ...EXTRA_HEADERS_FIELDS.extraFields);

      return headers;
    },

    calculateSkuCount(product, type = "mapped") {
      if (!product.articles.length) {
        return 0;
      }
      let count = 0;
      product.articles.forEach((prod) => {
        if (
          (type == "mapped" &&
          prod.marketplace_identifiers &&
          prod.marketplace_identifiers.sku) || (type == "active" && prod.active)
        ) {
          count++;
        } 
      });
      return count;
    },
    isEmpty(obj) {
      return isEmpty(obj);
    },
    hintChanged() {
      console.log("called hint element");
      this.isHintActive = !this.isHintActive;
      this.currentHintStage = 0;
      let isHintsAlreadyShown = localStorage.getItem("prod_hint_status");
      if (isHintsAlreadyShown) {
        localStorage.removeItem("prod_hint_status");
      } else if(!this.isHintActive) {
        localStorage.setItem("prod_hint_status", "shown");
      }
       document.cookie = "introjs-dontShowAgain" + '=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';  
      console.log("currentHint",this.currentHintStage, "ishintActive",this.isHintActive)
    },
    saveSkuMapping() {
      console.log("ths.productData", this.productData);

      console.log(
        "mapping errors",
        JSON.stringify(this.mappingsErrors, null, 2)
      );
      let item = this.productData;
      if (isEmpty(item))
        return this.$snackbar.global.showError("Not a valid mapping");
      let mappingData = {};

      let uniqueIdfs = {};
      let mkpId = "sku";
      mappingData["payload"] = item.articles.filter((article, idx) => {
        let valid = false;
        if (!article.active && !article.marketplace_identifiers.sku) return;
        else if (article.active && !article.marketplace_identifiers.sku) {
          this.productData.articles[idx].error =
            "Please provide marketplace identifier.";
          this.mappingsErrors.push("Invalid Marketplace Identifiers mappings.");
          return;
        }
        uniqueIdfs[mkpId] = uniqueIdfs[mkpId] || new Set();
        if (
          article.marketplace_identifiers[mkpId] &&
          !uniqueIdfs[mkpId].has(article.marketplace_identifiers[mkpId])
        ) {
          uniqueIdfs[mkpId].add(article.marketplace_identifiers[mkpId]);
          valid = true;
        } else {
          console.log("this.mappingerro", this.mappingsErrors);
          if (this.mappingsErrors.length == 0) {
            this.$snackbar.global.showError(
              "Marketplace Identifiers are duplicated"
            );
          }
          if (
            !this.mappingsErrors.length &&
            article.marketplace_identifiers[mkpId]
          )
            this.mappingsErrors.push("Marketplace Identifiers are duplicated");
        }
        return valid;
      });
      if (this.mappingsErrors.length) {
        this.mappingsErrors = [];
        this.$forceUpdate();
        return;
      }

      mappingData["payload"].forEach((payload) => {
        payload.item = {
          item_code: item.item_code,
          uid: item.uid,
        };
        payload.brand_uid = item.brand_uid;
      });
      if (this.mappingsErrors && this.mappingsErrors.length) return;
      this.inProgress = true;
      this.uploadMapping(mappingData)
        .then(() => {
          this.inProgress = false;
          this.$snackbar.global.showSuccess("Mapping saved successfully");
          this.$router.push({
            path: `products?hint=1`,
          });
        })
        .catch((err) => {
          this.inProgress = false;
          this.$snackbar.global.showError("Failed to save sku mapping");

          let errJson = err && err.response && err.response.data;
          if (
            err &&
            err.response &&
            err.response.headers &&
            err.response.headers["content-type"] !== "application/json"
          ) {
            return;
          }
          for (let rowIndex in errJson) {
            let error = errJson[parseInt(rowIndex)].marketplace_identifiers
              ? errJson[parseInt(rowIndex)].marketplace_identifiers[0] + "  "
              : "";
            this.mappingsErrors.push(error);
            break;
          }
          this.$router.push({
            path: `products?hint=1`,
          });
        });
    },
    uploadMapping(mappingData) {
      console.log("mapping data", mappingData);
      return AdminMarketplacesService.uploadSKUMapping(mappingData);
      // .catch(
      //     err => {
      //         return Promise.reject(err);
      //     }
      // );
    },
    cancelProductMapping() {
      this.$router.push({
        path: `products?hint=1`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../node_modules/@gofynd/nitrozen-vue/dist/nitrozen.css";
@import "../less/theme.less";
@import "../less/media.less";
@import "../less/text.less";
@import "../less/color.less";
@import "../less/page-header.less";
@import "../less/variables.less";
.breadcrumbs {
  margin: 24px;
  padding-left: 24px;
}
.funnel-container {
  margin: 24px;
  padding: 24px;
  background: #ffffff;
  border-radius: 12px;
  border: 0.5px solid gray;

  .funnel-top-span {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .funnel-head {
      width: 80%;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 156% */

      /* Text/primary */

      color: #41434c;
    }
    .funnel-sm-text {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height, or 142% */

      /* Text/secondary */

      color: #666666;
    }
    .funnel-sm-bold-text {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      /* identical to box height */

      text-align: right;

      /* Text/primary */

      color: #41434c;
    }
  }

  .funnel-cards {
    display: flex;
    align-items: baseline;

    .funnel-card {
      padding: 12px;
      //   opacity: 0.1;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 4px;
      width: 18%;
      margin: 0px 24px;

      .funnel-card-title {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 150% */

        /* Text/secondary */

        color: #666666;
      }
      .funnel-card-model {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height, or 120% */

        /* Text/primary */

        color: #41434c;
      }
    }
    .funnel-card-active {
      background-color: #e7eeff;
    }
    .funnel-stats {
      .funnel-stat-percentage {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 150% */

        /* Gray 3 */

        color: #828282;
      }
    }
  }
}
.fp-stats {
  margin-top: -50px;
  margin-left: auto;
}
.mkps {
  display: inline-flex;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.number-width {
  // min-width: 140px;
  width: 100%;
}
.cursor-disabled {
  cursor: not-allowed;
}
.hint-mkp-prod-id-block {
  display: flex;
  justify-content: space-around;
  :deep(.nitrozen-tooltip-left) {
    top: -80px;
  }
}
.pagination {
  margin-top: 20px;
}
.header-tooltip {
  margin: 2px 0px 2px 2px;
  :deep(.nitrozen-tooltiptext) {
    left: -50px;
    padding: 10px;
    line-height: 1.6;
    margin-bottom: 5px;
  }
  :deep(.nitrozen-tooltip-top:after) {
    left: 60%;
  }
}
.manual-inv-diag {
  :deep(.nitrozen-dialog-body) {
    overflow: unset !important;
  }
}
.manual-inv-label {
  margin-top: 30px;
}
.trigger-btn {
  margin-right: 20px;
}
.sync-button {
  display: block;
  margin: 24px 0px 20px;
}
.brands-body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .selected-brands {
    border: 2px solid RoyalBlue !important;
  }
  .brands-div {
    margin: 0 24px 24px 0;
    border: 2px solid White;
    cursor: pointer;
    padding: 6px;
    height: 100px;
    width: 90px;
    box-sizing: border-box;
    .brand-img-div {
      background-color: Alabaster2;
      height: 60px;
      width: 70px;
      margin: auto;
      .brand-img {
        width: 100%;
        height: 100%;
      }
    }
    .brand-name {
      text-align: center;
      margin: 10px 0px;
      line-height: 1.2;
      font-size: 11px;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
.page-error {
  padding: 1%;
}
.no-content {
  :deep(img) {
    height: 250px;
    width: 300px;
  }
}
.csv-preview-diag {
  :deep(.nitrozen-dialog-body) {
    overflow: auto;
    max-height: 600px;
  }
}
.tooltip {
  line-height: 1.6;
  margin-left: 5px;
  :deep(.nitrozen-tooltiptext) {
    padding: 15px;
    text-align: left;
    min-width: 210px;
  }
  :deep(.nitrozen-tooltip-bottom) {
    margin-left: -70px;
  }
}
.edit-icon {
  cursor: pointer;
  float: right;
  :deep(svg) {
    height: 24px;
    width: 24px;
  }
}
.pad-left-top {
  margin-left: 20px;
  margin-top: 20px;
}

.search {
  margin-right: 15px;
  width: 350px;
}
.input-box {
  // width: 400px;
  display: flex;
  justify-content: space-between;
  margin: 24px 0px;
  background-color: #f8f8f8;
  border-radius: 4px;
  // border: 1px solid #e0e0e0;
  padding: 12px;
  // @media @mobile {
  //     display: block;
  // }

  :deep(.nitrozen-dropdown-container) {
    flex-direction: row;
  }
  .status-filter {
    :deep(.nitrozen-dropdown-label) {
      margin: 12px;
    }
    :deep(.nitrozen-select-wrapper) {
      min-width: 200px;
    }
    :deep(.nitrozen-select__trigger) {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 23px;
      color: #41434c;
      opacity: 0.8;
    }
  }
  .btns-filters {
    display: flex;
    flex-wrap: wrap;
    input[type="file"] {
      display: none;
    }
  }
}
.table-container {
  padding: 24px;
  margin: 24px;
  background-color: White;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  // height: 85vh
  height:auto;
  // @media @mobile {
  //     margin: 24px 0 48px 0;
  // }
  .product-map-block {
    width: 92%;
    .product-map-txt {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      margin: 0px;
      /* identical to box height, or 140% */

      color: #41434c;
    }
    .product-map-desc {
      margin-top: 12px;
      margin-bottom: 12px;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height, or 142% */

      /* Text/secondary */

      color: #666666;
    }
    .image-name-block {
      display: flex;
      .product-brand-name-block {
        margin-top: 20px;
        margin-left: 14px;
        .product-name-block {
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 17px;
          /* identical to box height, or 113% */

          color: #5a5a5a;
        }
        .brand-name-block {
          font-family: Inter, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          margin-top: 10px;

          /* identical to box height, or 142% */

          color: #5a5a5a;
        }
        .mapped-active-sku-block {
          margin-top: 10px;
          display: flex;
          .sku-count-block {
            background: #e7eeff;
            border-radius: 4px;
            padding: 4px 8px;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
          }
          .active-count-block {
            margin-left: 15px;
          }
        }
      }
    }
  }
  .table-container-top {
    width: 100%;
    display: flex;
    // align-items: baseline;
    .cancel-btn {
      margin-right: 24px;
    }
    .save-btn {
      margin-left: auto;
      height: fit-content;
    }
    .hint-save-btn-block {
      // margin-left: auto;
      top: -40px;
      // left: 300px;
      position: relative;
    }
  }
}
.headers {
  margin-top: 12px;
  display: flex;
  background-color: #f8f8f8;
  border: 3px;
  padding: 0px 12px;
  justify-content: space-between;
  .header {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height, or 142% */

    /* Text/primary */

    color: #41434c;

    flex: 0.2;
    padding: 15px 10px;
    text-overflow: ellipsis;
    // white-space: nowrap;
    // overflow: hidden;
    margin-right: 10px;
  }
  .sync-col-width {
    // flex: 0.1;
    // display: flex;
  }
  .location-code {
    min-width: 145px;
  }
}
.row-container {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  border-top: none;
  overflow-y: scroll;
  max-height: 40vh;
  // overflow-y: scroll;
  // max-height: 50vh;
  .row {
    // display: flex;
    margin: 0px 12px;
    border-bottom: 1px solid #e0e0e0;
    .sku-row {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
    .sku-row-flex {
      align-items: baseline;
    }
    .row-value {
      display: flex;
      align-items: center;
      flex: 0.2;
      padding: 15px 10px;
      margin-right: 10px;
      .text-overflow {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        /* identical to box height, or 142% */

        /* Text/secondary */

        color: #666666;
      }
    }
    .input-mkp-id {
      display: unset;
    }
    .row-value-flex {
      // display: unset;
      display: flex;
      flex: 0.2;
      padding: 15px 10px;
      margin-right: 10px;
    }
    .sync-col-width {
      // flex: 0.1;
    }
    .location-code {
      min-width: 145px;
    }
    .input-error {
      display: flex;
      flex-direction: column;
      position: relative;
      top: 10px;
      padding-bottom: 0;
    }
    .handle {
      // width: 130px !important;
    }
    .sku-error {
      // margin-left: 16px;
      max-width: 230px;
      margin-bottom: 12px;
    }
  }
}

.regular-xsp {
  font-weight: 400;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
}
.darker-sm {
  font-weight: 600;
  font-size: 16px;
}
.main {
  position: relative;
  top: 10px;
}
#op {
  position: relative;
  top: 10px;
}
</style>
